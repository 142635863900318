//不同系统配置
export const systemConfig = [
  {
    displayName: "金恪康养会员系统",
    merchantId: 200,
    appId: "200",
    default: "healthCard",
    children: [
      {
        name: "康养会员卡",
        code: "healthCard",
        component: () => import("@/views/VipItems/Health/HealthVip"),
      },
      {
        name: "我的会员卡",
        code: "myCard",
        component: () => import("@/views/VipItems/Health/MyVip"),
      },
    ],
  },
];

export const systemConfigMap = systemConfig.reduce((s, v) => {
  s[v.merchantId] = v;
  return s;
}, {});
