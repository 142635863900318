<template>
  <div class="vip-viewer">
    <nav-header :backUrl="'/index'" :showRight="true"></nav-header>
    <div class="vip-viewer-header">
      <div class="vip-viewer-header-title">
        {{ currentConfig.displayName || "未查询到系统配置" }}
      </div>
    </div>
    <a-tabs
      class="nav-tabs"
      :default-active-key="this.tabSpecial || currentConfig.default"
      @change="tabChange"
      v-if="currentConfig.merchantId"
    >
      <a-tab-pane
        v-for="item in currentConfig.children"
        :key="item.code"
        :tab="item.name"
      >
        <component :is="item.component" />
      </a-tab-pane>
    </a-tabs>
    <nav-footer></nav-footer>
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader";
import NavFooter from "@/components/NavFooter";
import { systemConfigMap } from "@/const/system";
import { isIOSBrowser } from "@/helper";
export default {
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      tabSpecial: "",
    };
  },
  created() {
    this.tabSpecial =
      this.$route.params.tab || localStorage.getItem("viewerTab") || "";
  },
  mounted() {
    if (isIOSBrowser()) {
      setTimeout(() => {
        [...document.querySelectorAll(".vip-viewer .ant-tabs-tabpane")].forEach(
          (v) => {
            v.style.height = "calc(100vh - 3rem)";
          }
        );
      });
    }
  },
  methods: {
    tabChange(v) {
      localStorage.setItem("viewerTab", v);
    },
  },
  computed: {
    currentConfig() {
      const merchantId = this.$store.state.merchantId;
      if (merchantId) {
        return systemConfigMap[merchantId];
      }
      return {};
    },
  },
};
</script>
<style lang="scss" scoped>
.vip-viewer {
  padding-top: 0.5rem;
  .vip-viewer-header {
    .vip-viewer-header-title {
      color: #111;
      font-size: 0.26rem;
      padding: 0 0.15rem;
      line-height: 0.5rem;
      font-weight: 600;
    }
  }
  ::v-deep {
    .ant-tabs-tabpane {
      height: calc(100vh - 2rem);
      overflow: auto;
    }
  }
}
</style>